.page-user {
  display: flex;
  padding: .6rem;
  background: #FFF;
}
.page-user .pic {
  width: 1.6rem;
  height: 1.6rem;
  position: relative;
  z-index: 10;
  overflow: hidden;
  border-radius: .22rem;
}
.page-user .pic img {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate3d(-50%, -50%, 0);
}
.page-user .info {
  height: 1.6rem;
  display: flex;
  margin-left: .4rem;
  flex-direction: column;
  justify-content: center;
}
.page-user .info a:nth-child(1) {
  font-size: 0.48rem;
  color: #333;
  font-weight: 600;
  margin-bottom: .1rem;
}
.page-user .info a:nth-child(2) {
  font-size: 0.37333333rem;
  color: #999;
}
.page-group {
  margin-top: .4rem;
}
.page-group .item {
  height: 1.44rem;
  position: relative;
  background: #FFF;
}
.page-group .item a {
  font-size: 0.45333333rem;
  color: #37383d;
  display: block;
  height: 100%;
  height: 1.44rem;
  display: flex;
  align-items: center;
  padding: 0 .4rem;
}
.page-group .item.link:before {
  content: '';
  display: block;
  width: .24rem;
  height: .24rem;
  border-top: .05rem solid #333;
  border-left: .05rem solid #333;
  position: absolute;
  right: .4rem;
  top: 50%;
  margin-top: -0.13rem;
  transform: rotate(135deg);
}
.page-group .item.logout {
  margin-top: .4rem;
}
.page-group .item.logout a {
  justify-content: center;
  color: #a0a4a3;
}
