.page                       {
    &-user                  {display: flex;padding: .6rem;background: #FFF;
        .pic                {width: 1.6rem;height: 1.6rem;position: relative;z-index: 10;overflow: hidden;border-radius: .22rem;
            img             {position: absolute;top:50%;left: 50%;width: 100%;transform: translate3d(-50%, -50%, 0);}
        }
        .info               {height: 1.6rem;display: flex;margin-left: .4rem;flex-direction: column;justify-content: center;
            a               {
                &:nth-child(1){.fs(18);color: #333;font-weight: 600;margin-bottom: .1rem;}
                &:nth-child(2){.fs(14);color: #999;}
            }
        }
    }

    &-group                 {margin-top: .4rem;
        .item               {height: 1.44rem;position: relative;background: #FFF;
            a               {.fs(17);color: #37383d;display: block;height: 100%;height: 1.44rem;display: flex;align-items: center;padding: 0 .4rem;}
            &.link:before   {content: '';display: block;width: .24rem;height: .24rem;border-top: .05rem solid #333;border-left: .05rem solid #333;position: absolute;right: .4rem;top: 50%;margin-top: -.13rem;transform: rotate(135deg);}
            &.logout        {margin-top: .4rem;
                a           {justify-content: center;color: #a0a4a3;}
            }
        }
    }

}